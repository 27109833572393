<template>
  <header class="navbar">
    <router-link class="logo" to="/">
      <img src="../assets/totem-logo.png" alt="">
    </router-link>

    <nav class="nav-items">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/teams">Teams</router-link>
      <router-link to="/map">Map</router-link>
      <router-link to="/events">Events</router-link>
      <router-link to="/games-showcase">Games Showcase</router-link>
      <router-link to="/join-us" v-if="displayJoinUs">Join us</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav>

    <div class="nav-burger">
      <i class="far fa-2x fa-bars"></i>
    </div>

    <div class="nav-screen">
      <header>
        <div class="logo">
          <img src="../assets/totem-logo.png" alt="">
        </div>
        <i class="far fa-lg fa-chevron-left nav-close"></i>
      </header>
      <nav>
        <router-link to="/"><i class="far fa-fw fa-home"></i><span>Home</span></router-link>
        <router-link to="/about"><i class="far fa-fw fa-info"></i><span>About</span></router-link>
        <router-link to="/teams"><i class="far fa-fw fa-users"></i><span>Teams</span></router-link>
        <router-link to="/map"><i class="far fa-fw fa-map-marked-alt"></i><span>Map</span></router-link>
        <router-link to="/events"><i class="far fa-fw fa-calendar-alt"></i><span>Events</span></router-link>
        <router-link to="/games-showcase"><i class="far fa-fw fa-gamepad-alt"></i><span>Games Showcase</span></router-link>
        <router-link to="/join-us" v-if="displayJoinUs"><i class="far fa-fw fa-flag"></i><span>Join us</span></router-link>
        <router-link to="/contact"><i class="far fa-fw fa-envelope-open-text"></i><span>Contact</span></router-link>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Navbar',
  data: () => {
    return {
      body: null,
      overlay: null,
      navburger: null,
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Initialize variables
      this.body = document.querySelector('body')
      this.overlay = document.querySelector('#page-overlay')
      this.navburger = document.querySelector('.nav-burger')
      this.mobileNav = document.querySelector('.nav-screen')

      if (this.navburger) this.navburger.onclick = (e) => this.openMobileNav(e)
    })
  },
  computed: {
    ...mapState({
      displayJoinUs: state => state.settings.activeOptionalPages.recruitment
    })
  },
  methods: {
    openMobileNav() { 
      this.body.classList.add('overflow-hidden')
      this.overlay.classList.add('active', 'dark')
      this.mobileNav.classList.add('active')

      this.overlay.onclick = () => this.closeMobileNav()
      this.mobileNav.onclick = (e) => {
        if (e.target.closest('a') || e.target.closest('.nav-close')) {
          this.closeMobileNav()
        } else {
          e.stopPropagation()
        }
      }
    },
    closeMobileNav() {
      this.body.classList.remove('overflow-hidden')
      this.overlay.classList.remove('active', 'dark')
      this.mobileNav.classList.remove('active')
    }
  }
}
</script>

<style lang="scss">
.navbar {
  display: flex;
  width: 100%;
  height: var(--navbar-height);
  align-items: center;
  justify-content: space-between;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  background-color: var(--color-bg);
  border-bottom: 2px solid #445;

  .nav-burger, .nav-screen {
    display: none;
  }

  .nav-items a {
    color: #aaa;
    text-decoration: none;
    transition: 200ms ease;

    & + a {
      margin-left: 24px;
    }

    &:hover, &.router-link-exact-active {
      color: #fff;
    }
  }

  .logo {
    display: block;

    img {
      height: 2.5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    .nav-items { display: none; }
    .nav-burger, .nav-screen { display: block; }
  }

  .nav-screen {
    --mobile-nav-width: 300px;
    position: fixed;
    left: calc((var(--mobile-nav-width) * -1) - 16px);
    top: 0;
    height: 100%;
    width: var(--mobile-nav-width);
    background-color: #444455;
    padding: 0 2.5rem;
    border-radius: 0 1rem 1rem 0;
    z-index: 11;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
    transition: left 200ms ease;

    &.active { display: block; left: 0; }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 5rem;
      margin-bottom: 2rem;
      border-bottom: 2px solid var(--color-bg-light);

      i {
        padding: 10px;
        padding-right: 5px;
      }
    }

    nav a {
      display: block;
      padding: 0.75em 1.5em;
      text-decoration: none;
      color: #fff;
      border-radius: 1000px;
      transition: 200ms ease;
      font-size: 16px;

      i {
        margin-right: 1rem;
      }

      & + a { margin-top: 8px; }

      &:hover, &.router-link-exact-active {
        background: var(--color-purple);
      }
    }
  }
}
</style>
