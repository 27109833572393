import { checkAdmin } from '@/firebase'
import { getAuth } from 'firebase/auth'
import store from '../store'

import { useDevLog } from '../util'

const { devLog } = useDevLog()

/**
 * Function checking whether the user is allowed to visit the route they selected,
 * based on whether they are authenticated and whether they have the correct
 * permissions.
 * 
 * @param {*} to  The route the user is trying to visit
 * @param {*} from  The route the user is coming from
 * @param {*} next  The function to call to continue the route change
 * @returns 
 */
export function checkForAuth(to, from, next) {
  devLog("%cRouter%c: Running beforeEach to handle auth", 'color: indianRed', 'color: white')
  const user = getAuth().currentUser

  // Go to admin if user is already logged in when going to the login page
  if (to.name === 'Login') {
    if (user) {
      next({ name: 'Dashboard' })
      return
    } else if (from.name === undefined) {
      devLog("%cRouter%c: dispatching fetchUser for login skip check", 'color: indianRed', 'color: white')
      store.dispatch('fetchUser').then((result) => {
        devLog("%cRouter%c: dispatch fetchUser returned", 'color: indianRed', 'color: white')
        if (result === true) {
          next({ name: 'Dashboard' })
          return
        } else {
          next()
          return
        }
      })
    } else if (!user) {
      next()
      return
    }

    // If not initial load and user is null, simply not logged in. Continue to login using next.
    // This is automatically done by the code below, no separate call necessary.
  }

  // Check if the path requires authentication and user is not set
  else if (to.matched.some(record => record.meta.authRequired)) {
    if (user) {
      // The user is authenticated. Does this route also require admin permissions?
      if (to.matched.some(record => record.meta.adminRequired)) {
        checkAdmin(store.state.user.uid).then(isAdmin => {
          if (isAdmin) {
            next()
          } else {
            next({ name: "LoginSojourn" })
          }
        })
      } else if (to.name === 'LoginSojourn') {
        checkAdmin(store.state.user.uid).then(isAdmin => {
          if (isAdmin) {
            next({ name: "Dashboard" })
          } else {
            next()
          }
        })
      } else {
        next()
        return
      }
    }
    else {

      // User is not set, check if this is initial load. All internal routes are named,
      // so the statement below only runs if it is actually the initial load.
      if (from.name === undefined) {
        devLog("%cRouter%c: dispatching fetchUser for auth required check", 'color: indianRed', 'color: white')

        store.dispatch('fetchUser').then((result) => {
          devLog("%cRouter%c: dispatch fetchUser returned", 'color: indianRed', 'color: white')
          if (result === false) {
            next({ name: 'Login', query: { error: 'no-auth' } })
            return
          } else {
            // The user is authenticated. Does this route also require admin permissions?
            if (to.matched.some(record => record.meta.adminRequired)) {
              checkAdmin(store.state.user.uid).then(isAdmin => {
                if (isAdmin) {
                  next()
                } else {
                  next({ name: "LoginSojourn" })
                }
              })
            } else if (to.name === 'LoginSojourn') {
              checkAdmin(store.state.user.uid).then(isAdmin => {
                if (isAdmin) {
                  next({ name: "Dashboard" })
                } else {
                  next()
                }
              })
            } else {
              next()
              return
            }
          }
        })
      } else {
        // If not initial load, user is simply not logged in. Go to login.
        next({ name: 'Login', query: { error: 'no-auth' } })
        return
      }

    }
  }

  else next()
  return
}