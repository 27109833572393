import { ref } from 'vue'

const defaultDialogOptions = {
  type: "alert", // alert, alert-warning, alert-warning-remove
  title: "Dialog",
  message: "A message was not provided.",
  options: [
    {
      type: 'confirm',
      text: 'Confirm',
      callback: () => {}
    },
    {
      type: 'cancel',
      text: 'Cancel',
      callback: () => {}
    }
  ]
}

export default function useDialogs() {
  const dialogs = ref([])

  const createDialog = (options) => {
    const fOptions = Object.assign({ ...defaultDialogOptions}, options)
    dialogs.value.push(
      {
        id: createUUID(),
        ...fOptions,
      },
    )
  }

  const destroyDialog = (id) => {
    const index = dialogs.value.findIndex((item) => item.id === id)
    if (index !== -1) dialogs.value.splice(index, 1)
  }

  return {
    dialogs,
    createDialog,
    destroyDialog
  }
}

// Utility: Generate UUIDs
function createUUID() {
  let dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}