import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from 'firebase/auth'
import { createStore } from 'vuex'
import router from '../router'
import { initUser } from '@/firebase'
import { useDevLog } from '../util'

const { devLog } = useDevLog()

export default createStore({
  state: {
    user: null,
    games: [
      /*{
        id: 1,
        name: "Mythos",
        tags: ['Platformer', 'Singleplayer', 'Fantasy', 'Lovecraft'],
        description: "Mythos is an action platformer RPG that brings out the Lovecraftian in all of us. As the player  traverses through the world, they figure out that reality and truth are very different things. Uncover secret endings and dangerous truths as you are challenged to the point of insanity by the physical manifestations of reality's true nature.",
        thumbnail: 'mythos-2.png',
        images: ['mythos-1.jpg', 'mythos-2.png', 'mythos-3.png'],
        activeImage: 0,
        team: ['Ongun Korkmaz', 'David Boguslavsky', 'Daisy Wan', 'Vivian Sam', 'Danny Deng', 'Amy Lee', 'Advik Ayya', 'Jin Kim', 'TJ Wu', 'Tyler Mann', 'Ethan Wescoatt', 'Meaghan Marina', 'Daniel Steiner'],
      },*/
      {
        id: 2,
        name: "Money Monkey",
        tags: ['Platformer', 'Singleplayer', ' 2D', 'Pixel Art'],
        description: 'Become a monkey and go back to your roots by collecting money, the root of all evil, which can buy you bananas.',
        thumbnail: 'money-monkey-4.png',
        images: ['money-monkey-2.png', 'money-monkey-3.png', 'money-monkey-4.png'],
        activeImage: 0,
        team: ['Unstoppable_4', 'Tigo13'],
        itchLink: 'https://unstoppable-4.itch.io/money-monkey',
      },
      {
        id: 3,
        name: "Gun Lord",
        tags: ['First-Person Shooter', 'Singleplayer', 'Aliens', 'Retro'],
        description: "You are an DOOM and Quake obsessed person. One day you fall asleep and in your dreams the worst thing imaginable happens. Aliens have invaded earth! You pull out your shotgun and attempt to fight them but to no avail. You fall into a even deeper slumber and you appear in front of you final foe. Your task is now to defeat the leader of the aliens in his mech suit to save the earth and humanity.",
        thumbnail: 'gun-lord-1.png',
        images: ['gun-lord-2.png', 'gun-lord-3.png', 'gun-lord-4.png'],
        activeImage: 0,
        team: ['Erkin Uyguroglu', 'Willem Ploegstra'],
        downloadLink: 'https://mrwiem.itch.io/gun-lord',
        itchLink: 'https://mrwiem.itch.io/gun-lord',
        gameDevTeam: true,
      },
      {
        id: 4,
        name: "Unearth",
        tags: ['Action', 'Singleplayer', 'Hack and Slash', 'Story Rich'],
        description: "",
        thumbnail: 'unearth-1.png',
        images: ['unearth-1.png'],
        activeImage: 0,
        team: ['Sogii'],
        itchLink: 'https://sogii.itch.io/unearth',
      },
      {
        id: 5,
        name: "Reroot",
        tags: ['Platformer'],
        images: ['reroot-1.png', 'reroot-2.png'],
        activeImage: 0,
        team: ['100procentnl'],
        itchLink: 'https://100procentnl.itch.io/reroot',
      },
      {
        id: 6,
        name: "Growlight",
        tags: ['Action', 'Singleplayer', 'Atmospheric', 'GGJ2023'],
        description: "A tree root grows underground. You are the hard working Growlight, from a race of ancient beings known as Will-o'-the-wisp, sent from mother Earth to help guide each particular tree's roots to a healthy growth.",
        thumbnail: 'growlight-2.jpeg',
        images: ['growlight-1.jpeg', 'growlight-2.jpeg', 'growlight-3.png'],
        activeImage: 0,
        team: ['Roman Dotsenko', 'Aleksandar Djordjevic', 'Igor Djordjevic'],
        itchLink: 'https://makeadent.itch.io/growlight',
      },
      {
        id: 7,
        name: "The Unfair Game",
        tags: ['Strategy', 'Singleplayer', 'Board Game', 'Dice', 'DND'],
        description: "The Unfair Game is a digital board game with DND-inspired combat elements. Role the dice and move your character around the board to gain points by finishing loops and defeating enemies. Try to get the highest score you can!",
        thumbnail: 'the-unfair-game-2.png',
        images: ['the-unfair-game-1.png', 'the-unfair-game-2.png'],
        activeImage: 0,
        team: ['Ongun Korkmaz', 'Jesse Traas', 'Fabrice'],
        itchLink: 'https://pxjesse.itch.io/the-unfair-game-gmtk',
      },
      {
        id: 8,
        name: "Observe",
        tags: ['Puzzle', 'Adventure', 'Singleplayer', '2D', 'Atmospheric'],
        description: "Forgotten in ominous rooms. You wander, you look, you solve. Help your future self to push through, crack puzzles and explore the ethereal.",
        thumbnail: 'observe-1.png',
        images: ['observe-1.png', 'observe-2.png', 'observe-3.png'],
        activeImage: 0,
        team: ['Robin van der Horst', 'Pauls Dinne', 'Eryk Kiepuszewski'],
        itchLink: 'https://epski.itch.io/observe',
        downloadLinks: [
          {
            icon: 'steam',
            iconStyle: 'fab',
            name: 'View on Steam',
            link: 'https://store.steampowered.com/app/2738190/Observe/',
          }, 
        ],
        gameDevTeam: true,
      },
      {
        id: 9,
        name: "Celestial Shift",
        tags: ['Platformer', 'Singleplayer', '2D', 'Pixel Art', 'Puzzle'],
        description: "You embark on a quest for redemption in a captivating realm. Once consumed by arrogance, your actions disrupted cosmic balance. Stripped of your physical form, you control gravity to navigate dangerous otherworldly environments. Manipulate gravity to forge a path of self-discovery, reunite your essence, and restore harmony. Can you reach the culmination of your redemptive journey?",
        thumbnail: 'celestial-shift-1.png',
        images: ['celestial-shift-1.png', 'celestial-shift-2.png'],
        activeImage: 0,
        team: ['1am2good4you'],
        itchLink: 'https://1am2good4you.itch.io/celestial-shift',
      },
      {
        id: 10,
        name: "Wordaway",
        tags: ['Puzzle', 'Mobile', 'Word Game'],
        mobile: true,
        description: "Wordaway lets you test your vocabulary skills and speed. In this fun game, you can compete with your friends or other players. It's simple: find the most and longest words in the given time with letter blocks that change each round. Whoever has the most points at the end of the match wins. Don't forget to strengthen yourself by using cards that will help you.",
        thumbnail: 'wordaway-logo.png',
        images: ['wordaway-1.webp', 'wordaway-2.webp', 'wordaway-3.webp'],
        activeImage: 0,
        downloadLinks: [
          {
            icon: 'google-play',
            iconStyle: 'fab',
            name: 'Download on Google Play',
            link: 'https://play.google.com/store/apps/details?id=com.kuark.wordaway',
          },
          {
            icon: 'app-store',
            iconStyle: 'fab',
            name: 'Download on App Store',
            link: 'https://apps.apple.com/tr/app/wordaway/id1658744403',
          },
        ],
      },
      {
        id: 11,
        name: "Orbit",
        tags: ['Simulation', 'Survival', 'Singleplayer', 'Space Sim'],
        description: "You have stranded in a remote solar system! To recharge your hyperdrive, you need to exploit the resources of the planets around you. Make your escape before the fuel runs out...",
        thumbnail: 'orbit-1.png',
        images: ['orbit-1.png', 'orbit-2.png', 'orbit-3.png'],
        activeImage: 0,
        team: ['ItsAPixel'],
        itchLink: 'https://itsapixel.itch.io/orbit',
      },
      {
        id: 12,
        name: "Open The Gates!",
        tags: ['RTS', 'Side Scroller', 'Singleplayer'],
        description: "Become king of your own castle in this strategic sidescrolling castlebuilder game. Manage the economy, build defenses and keep your citizens happy. Control diverse armies and fight in large-scale real-time strategy battles.",
        thumbnail: 'open-the-gates-thumbnail-vert.png',
        images: ['open-the-gates-thumbnail.png', 'open-the-gates-1.png', 'open-the-gates-2.png'],
        activeImage: 0,
        team: ['Robin van der Horst'],
        downloadLinks: [
          {
            icon: 'steam',
            iconStyle: 'fab',
            name: 'View on Steam',
            link: 'https://store.steampowered.com/app/1332450/Open_The_Gates/',
          }, 
        ]
      },
      {
        id: 13,
        name: "The Flower Girl",
        tags: ['Platformer', 'Singleplayer'],
        description: "Join a cheerful girl in a peaceful forest, gathering flowers and meeting friendly creatures. Everything shifts when a menacing wolf appears, altering her serene world. Can you help her navigate and conquer the challenges that lie ahead?",
        thumbnail: 'the-flower-girl-1.png',
        images: ['the-flower-girl-1.png', 'the-flower-girl-2.png', 'the-flower-girl-3.png'],
        activeImage: 0,
        team: ['Fleur Lips', 'Thirza Vermeulen', 'Simon van Hooijdonk', 'Nick van Wiggen'],
        downloadLinks: [
          {
            icon: 'play',
            iconStyle: 'fas',
            name: 'Play on GDevelop Games',
            link: 'https://gd.games/whokoala/flower-girl',
          },
        ]
      },
      {
        id: 14,
        name: "Telescape",
        tags: ['Puzzle', 'Horror', 'Shooter'],
        description: "Find items, solve puzzles and change the direction of gravity while teleporting a monster to a random location as your only means of defense.",
        thumbnail: 'telescape-1.jpg',
        images: ['telescape-1.jpg', 'telescape-2.jpg', 'telescape-3.jpg'],
        activeImage: 0,
        team: ['Dvoid Productions'],
        downloadLinks: [
          {
            icon: 'steam',
            iconStyle: 'fab',
            name: 'View on Steam',
            link: 'https://store.steampowered.com/app/2586900/Telescape/',
          }, 
        ],
        gameDevTeam: false,
				sponsored: true,
      },
    ],
    settings: {
      homeCard: {
        display: false,
        icon: 'users',
        header: `We're looking for team members!`,
        body: `We are looking for board members for the next academic year. If you're interested, check out the Join us page <a href="/join-us" class="link">here</a>!`
      },
      activeOptionalPages: {
        recruitment: false,
      }
    },
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    clearUser (state) {
      state.user = null
    }
  },
  actions: {
    async login ({ commit }, details) {
      const { email, password } = details
      const auth = getAuth()

      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (error) {
        return error.code
      }

      commit('setUser', auth.currentUser)

      router.push('/admin')
      
      return true
    },
    async googleLogin ({ commit }) {
      const googleProvider = new GoogleAuthProvider()
      const auth = getAuth()

      try {
        const result = await signInWithPopup(auth, googleProvider)
        const user = result.user

        const newUser = getAdditionalUserInfo(result).isNewUser

        if (newUser) {
          await initUser(user)
          return true
        } else {
          return true
        }
      } catch (error) {
        return error.code
      }
    },
    async logout ({ commit }) {
      const auth = getAuth()
      try {
        await signOut(auth)
        router.push('/')
        return true
      } catch (error) {
        console.log(`Errorred :${error}`)
        return error.code
      }
    },
    async fetchUser ({ commit }) {
      devLog("%cStore%c: Executing fetchUser action...", 'color: gold', 'color: white')
      const auth = getAuth()
      const ascPromiseResult = await new Promise(res => {
        auth.onAuthStateChanged(user => {
          if (user === null) {
            devLog("%cStore (ASC)%c: No user found", 'color: gold', 'color: white')
            commit('clearUser')
            res(false)
          } else {
            devLog("%cStore (ASC)%c: User found", 'color: gold', 'color: white')
            commit('setUser', user)
            res(true)
          }
        })
      })
      devLog("%cStore%c: Finished fetchUser, returning promise value", 'color: gold', 'color: white')
      return ascPromiseResult
    }
  },
  modules: {
  }
})
