<template>
  <Navbar />
  <div class="content-wrapper">
    <slot></slot>
  </div>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
}
</script>

<style lang="scss">
// Keep footer always at the bottom
.content-wrapper {
  padding-bottom: var(--footer-height);
}
</style>
