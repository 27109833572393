// General settings
const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' }
const timeFormat = { timeStyle: 'short', hourCycle: 'h24' }


/**
 * Get the dev logger
 * 
 * @returns {Object} Object containing the devLog function
 */
export function useDevLog() {
  const devLog = (msg, ...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(msg, ...args)
    }
  }

  return {
    devLog
  }
}


/**
 * Function to toggle the dimmer overlay
 * 
 * @param {Boolean} bool Whether to show or hide the dimmer
 * @param {Function} onClickCallback Callback function to run when the dimmer is clicked
 */
export function toggleDimmer(bool, onClickCallback = null) {
  const body = document.querySelector('body')
  const dimmer = document.querySelector('#page-overlay')

  if (bool) {
    body.classList.add('overflow-hidden')
    dimmer.classList.add('active', 'dark')

    // Add the event listener to execute the onClickCallback
    if (onClickCallback) {
      dimmer.onclick = onClickCallback
    }
  } else {
    body.classList.remove('overflow-hidden')
    dimmer.classList.remove('active', 'dark')

    // Remove the event listeners by re-creating the element
    const newDimmer = dimmer.cloneNode(true)
    dimmer.parentNode.replaceChild(newDimmer, dimmer)
  }
}


/**
 * Convert a string to a date
 * 
 * @param {String} str The string to convert to a date
 * @returns {Date} The date object
 */
export function stringToDate(str) {
  let d = parseInt(str.substring(0, 2))
  let m = parseInt(str.substring(3, 5)) - 1
  let y = parseInt(str.substring(6, 10))
  let h = parseInt(str.substring(11, 13))
  let mins = parseInt(str.substring(14, 16))

  try {
    const date = new Date(y, m, d, h, mins)

    // Date validation: Invalid format
    if (date.getMonth() !== m) {
      throw 'The entered day is not within the entered month'
    }

    // New idea for validation: Check all numbers for exactness due to overflows. Also 25 hours for example
    return date
  } catch {
    return false
  }
}


/**
 * Formats a date string from the stringToDate input format to the English locale
 * {date} — {time} string, where the format of the date and time is defined in the
 * general settings
 * 
 * @param {String} str The date string to format
 * @returns {String} The formatted date string
 */
export function formatDateString(str) {
  const date = stringToDate(str)
  if (date) {
    return `${date.toLocaleString('EN-en', dateFormat)} — ${date.toLocaleString('EN-en', timeFormat)}`
  } else {
    return `—`
  }
}


/**
 * Converts a date to a string in the format of dd/mm/yyyy hh:mm
 * 
 * @param {Date} d The date to convert to a string
 * @returns {String} The string representation of the date
 */
export function dateToString(d) {
  return `${pad(d.getDate(), 2)}/${pad(d.getMonth() + 1, 2)}/${d.getFullYear()} ` +
    `${pad(d.getHours(), 2)}:${pad(d.getMinutes(), 2)}`
}


/**
 * Validates a given URL
 * 
 * @param {String} string URL to validate
 * @returns {Boolean} Whether or not the provided URL is a valid URL
 */
export function validURL(string) {
  let url

  try {
    url = new URL(string)
  } catch {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}


/**
 * Internal function to pad (prefix) a number with the provided pad character
 * until the string representation is of length `padlen`
 * 
 * @param {Number} num Number to pad
 * @param {Number} padlen The amount of characters the string should be
 * @param {String} padchar The character to use as padding character
 * @returns {String} The padded string
 */
function pad(num, padlen, padchar) {
  var pad_char = typeof padchar != 'undefined' ? padchar : '0';
  var pad = new Array(1 + padlen).join(pad_char);
  return (pad + num).slice(-pad.length);
}


/**
 * Media drag and drop functions
 */
export function highlight(dropArea) {
  if (!dropArea.classList.contains('highlight')) dropArea.classList.add('highlight')
}

export function unhighlight(dropArea) {
  dropArea.classList.remove('highlight')
}

export function displayDropArea(dropArea) {
  if (!dropArea.classList.contains('highlight')) dropArea.classList.add('droppable')
}

export function hideDropArea(dropArea) {
  dropArea.classList.remove('droppable')
}