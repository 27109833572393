<template>
  <div :class="['notification', type]" @click="destroyNotification">
    <i :class="[`fal fa-lg fa-${iconFromType}`]"></i>
    <div class="text">
      <strong>{{ title }}</strong>
      <p>{{ message }}</p>
    </div>
    <svg class="progress-ring" height="32" width="32">
      <circle class="progress-ring-template" stroke-width="4" fill="transparent" r="10" cx="16" cy="16" />
      <circle class="progress-ring-circle" stroke-width="4" fill="transparent" r="10" cx="16" cy="16" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ToastNotification",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "info",
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: "Oops! A message was not provided.",
      required: false
    },
    duration: {
      type: Number,
      default: 5,
      required: true
    }
  },
  emits: ['destroy'],
  data() {
    return {
      destroyTimer: null,
      progressRingEl: null,
      progressRingR: null,
      progressRingC: null
    }
  },
  methods: {
    destroyNotification() {
      this.$emit("destroy")
      // clearTimeout(this.destroyTimer)
      // this.$el.classList.add('unload')
      // let destroyDelay = 1000
      // setTimeout(() => this.$emit("destroyNotification", this.uuid), destroyDelay)
    },
    setProgress(percent) {
      const offset = this.progressRingC - percent / 100 * this.progressRingC;
      this.progressRingEl.style.strokeDashoffset = offset;
    }
  },
  computed: {
    iconFromType() {
      switch (this.type) {
        case 'confirm':
          return 'check-circle'
        case 'warning':
          return 'exclamation-triangle'
        case 'error':
          return 'exclamation-circle'
        default:
          return 'info-circle'
      }
    },
    toastTitle() {
      return this.title && this.title !== null ? this.title : "Notification"
    }
  },
  mounted() {
    this.destroyTimer = setTimeout(this.destroyNotification, this.duration * 1000)

    // Set up progress ring
    this.progressRingEl = this.$el.querySelector('.progress-ring-circle')
    this.progressRingR = this.progressRingEl.r.baseVal.value
    this.progressRingC = this.progressRingR * 2 * Math.PI
    this.progressRingEl.style.strokeDasharray = `${this.progressRingC} ${this.progressRingC}`;
    this.progressRingEl.style.strokeDashoffset = this.progressRingC;
    this.progressRingEl.style.strokeDashoffset = this.progressRingC;
    this.progressRingEl.style.transition = `stroke-dashoffset ${this.duration}s linear`;
    setTimeout(() => this.setProgress(100), 10)
  }
}
</script>

<style>
.notification {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-panel-hl-2);
  border-radius: 40px;
  padding: 1rem 2rem;
  font-size: 15px;
  max-width: 800px;
  height: 72px;
  margin-top: 1rem;
  animation: notifications-in 500ms var(--ease-in-out-quartic) forwards;
}
.notification.unload {
  animation: notifications-out 500ms var(--ease-in-out-quartic) forwards, notifications-squeeze 500ms var(--ease-in-out-quartic) 500ms forwards;
  pointer-events: none;
}
.notification.confirm { background-color: seagreen; }
.notification.info { background-color: cornflowerblue; }
.notification.warning { background-color: #ffCD1C; }
.notification.error { background-color: indianred; }
.notification .text {
  margin: 0 1.5rem;
}
.notification .progress-ring-template {
  stroke: rgba(255, 255, 255, .3);
}
.notification .progress-ring-circle {
  stroke: white;
  stroke-dasharray: 10 20;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
@keyframes notifications-in {
  from {
    opacity: 0;
    margin-right: -100px;
  } to {
    opacity: 1;
    margin-right: 0;
  }
}
@keyframes notifications-out {
  from {
    opacity: 1;
    margin-right: 0;
  } to {
    opacity: 0;
    margin-right: -100px;
  }
}
@keyframes notifications-squeeze {
  to {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
  }
}
</style>